<template>
  <div class="fieldset checkout-persoana" id="detalii-facturare-persoana">
    <Field
      type="checkbox"
      name="persoana-shipping-diff"
      value="1"
      id="persoana_billing_details"
      :rules="persoanaShippingDiff"
      @click="showForm"
    />

    <label for="persoana_billing_details">Folosesc alta adresa pentru livrare</label>
    <transition name="slide">
      <div id="livr_pf" class="position_left" v-if="showAddress">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="shipping_last_name">Nume:</label>
              <Field
                type="text"
                name="order.property.shipping_last_name"
                id="shipping_last_name"
                placeholder="Nume"
                class="form-control"
              />
              <ErrorMessage class="form-error" name="order.property.shipping_last_name" />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="shipping_first_name">Prenume:</label>
              <Field
                type="text"
                name="order.property.shipping_name"
                id="shipping_first_name"
                value=""
                placeholder="Prenume"
                class="form-control"
              />
              <ErrorMessage class="form-error" name="order.property.shipping_name" />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="info_req form-group">
              <label for="shipping_state">Judet:</label><span class="steluta"> *</span>
              <Field
                as="select"
                placeholder="Select"
                id="shipping_state"
                name="order.property.shipping_state"
                class="select2-container form-control"
                v-model="menuSelected"
              >
                <option value="">Selecteaza judet</option>
                <option v-for="state in judete" :value="state.nume" :key="state.auto">
                  {{ state.nume }}
                </option>
              </Field>

              <ErrorMessage class="form-error" name="order.property.shipping_state" />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="info_req form-group">
              <label for="shipping_city">Localitatea:</label
              ><span class="steluta"> *</span>
              <Field
                as="select"
                placeholder="Select"
                id="shipping_city"
                name="order.property.shipping_city"
                class="select2-container form-control"
                :disabled="userCities.length == 0"
                v-model="selecteduserCity"
              >
                <option value="">Selecteaza localitate</option>
                <option v-for="(userCity, index) in userCities" :key="index">
                  {{ userCity.nume }}
                </option>
              </Field>
              <ErrorMessage class="form-error" name="order.property.shipping_city" />
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="form-group">
              <label for="shipping_address">Strada:</label>
              <Field
                type="text"
                name="order.property.shipping_street"
                id="shipping_address"
                value=""
                placeholder="Strada"
                class="form-control"
              />
              <ErrorMessage class="form-error" name="order.property.shipping_street" />
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="form-group">
              <label for="shipping_number">Nr. Strada:</label>
              <Field
                type="text"
                name="order.property.shipping_house"
                id="shipping_number"
                value=""
                placeholder="Strada"
                class="form-control"
              />
              <ErrorMessage class="form-error" name="order.property.shipping_house" />
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="info_req form-group">
              <label for="shipping_zipcode">Cod Postal:</label>
              <Field
                type="text"
                name="order.property.shipping_postcode"
                id="shipping_zipcode"
                value=""
                placeholder="Cod postal"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="info_req form-group">
              <label for="shipping_block">Bloc:</label>
              <Field
                type="text"
                name="order.property.shipping_block"
                id="shipping_block"
                value=""
                placeholder="BLoc"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="info_req form-group">
              <label for="shipping_apartament">Apartament:</label>
              <Field
                type="text"
                name="order.property.shipping_flat"
                id="shipping_apartament"
                value=""
                placeholder="Apartament"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="info_req form-group">
              <label for="shipping_entrance">Intrare:</label>
              <Field
                type="text"
                name="order.property.shipping_entrance"
                id="shipping_entrance"
                value=""
                placeholder="Intrare"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="shipping_phone">Telefon:</label>
              <Field
                type="text"
                name="order.property.shipping_phone"
                id="shipping_phone"
                value=""
                placeholder="Numar telefon"
                class="form-control"
              />
              <ErrorMessage class="form-error" name="order.property.shipping_phone" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "AnotherAddress",
  data() {
    return {
      showAddress: false,
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
      name: "",
      persoanaShippingDiff: yup.boolean(),
      persoanaShippingName: yup.string().when("persoanaShippingDiff", {
        is: false,
        then: yup.string(),
        otherwise: yup.string().required(),
      }),
      menuSelected: "",
      userCities: [],
      selecteduserCity: "",
    };
  },

  watch: {
    menuSelected: function () {
      // Clear previously selected values
      // this.userCountries = [];
      this.userCities = [];
      // this.selecteduserCountry = "";
      this.selecteduserCity = "";
      // Populate list of userCountries in the second dropdown
      if (this.menuSelected.length > 0) {
        const county = this.judete.find((element) => element.nume === this.menuSelected);
        this.$store.dispatch("shop/getLocationData", county.id).then((response) => {
          this.userCities = response;
        });
      }
    },
  },
  computed: {
    judete() {
      return this.$store.getters["shop/judeteData"];
    },
  },
  created() {
    this.$store.dispatch("shop/getLocationData");
  },
  components: {
    Field,
    ErrorMessage,
  },
  methods: {
    showForm() {
      this.showAddress = !this.showAddress;
    },
    validateShippingName(value) {
      if (!this.showAddress) {
        return true;
      }
      if (!value) {
        return "Campul 'Nume' este obligatoriu.";
      }
      return true;
    },
    validateShippingAddress(value) {
      if (!value) {
        return "Campul 'Strada' este obligatoriu.";
      }
      return true;
    },
    validateShippingPhone(value) {
      if (!value) {
        return "Numarul de telefon trebuie sa contina cel putin 10 caractere.";
      }
      return true;
    },
    validateShippingNumber(value) {
      if (!value) {
        return "Campul 'Nr. strada' este obligatoriu.";
      }
      return true;
    },
    validateShippingLastName(value) {
      if (!value) {
        return "Campul 'Prenume' este obligatoriu.";
      }
      return true;
    },
    validateShippingState(value) {
      if (!value) {
        return "Campul 'Judet' este obligatoriu.";
      }
      return true;
    },
    validateShippingCity(value) {
      if (!value) {
        return "Campul 'Localitate' este obligatoriu.";
      }
      return true;
    },
    generalValidation() {
      if (!this.name) {
        return this.name;
      }
      return true;
    },
  },
};
</script>
