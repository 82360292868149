<template>
  <section class="form row">
    <div class="col-12 needs-validation _ajax_create_order">
      <div class="date-facturare-holder">
        <div class="checkout_biling_type_content">
          <input type="hidden" name="checkout_type" value="" id="checkout_type" />
          <div
            class="checkout-button"
            @click="activeTab = 1"
            :class="[activeTab === 1 ? 'active' : '']"
          >
            <input
              type="radio"
              name="tip-fa"
              id="checkout_biling_person"
              value="persoana"
              checked
            />
            <label class="copy-detail" for="checkout_biling_person"
              ><strong>Persoana fizica</strong></label
            >
          </div>
          <div
            class="checkout-button"
            @click="activeTab = 2"
            :class="[activeTab === 2 ? 'active' : '']"
          >
            <input
              type="radio"
              name="tip-fa"
              id="checkout_biling_company"
              value="firma"
            />
            <label class="copy-detail" for="checkout_biling_company"
              ><strong>Persoana juridica</strong></label
            >
          </div>
        </div>

        <div class="checkout-area">
          <div
            :class="{
              'pers-fiz': activeTab === 1,
              'pers-jurid': activeTab === 2,
            }"
            :id="{ fizica: activeTab === 1, juridica: activeTab === 2 }"
          >
            <Form
              @submit="sendOrder"
              class="needs-validation _ajax_create_order"
              v-slot="{ errors }"
            >
              <template v-if="Object.keys(errors).length">
                <div class="alert_wrap">
                  <div class="alert_box">
                    <font-awesome-icon icon="exclamation-triangle" />
                    <p>Va rugam completati toate campurile!</p>
                  </div>
                </div>
              </template>
              <persoana-fizica v-if="activeTab === 1" :errors="errors" />
              <persoana-juridica v-if="activeTab === 2" :errors="errors" />
              <another-address :errors="errors" />
              <payment-methods :personType="activeTab === 1 ? 'fizica' : (activeTab === 2 ? 'juridica' : '')" :errors="errors" />
              <terms :errors="errors" />
              <form-buttons :created="createOrder" />
            </Form>
          </div>
        </div>
      </div>

      <div class="form-row _ajax_location_select_wrapper">
        <!-- <location-select /> -->
      </div>
    </div>
  </section>
</template>

<script>
import { Form } from "vee-validate";
import PersFizica from "@/components/checkout/PersFizica.vue";
import PersJuridica from "@/components/checkout/PersJuridica.vue";
import PaymentMethods from "@/components/checkout/PaymentMethods.vue";
import AnotherAddress from "@/components/checkout/anotherAddress.vue";
import Terms from "@/components/checkout/Terms.vue";
import formButtons from "@/components/checkout/formButtons.vue";
// import LocationSelect from "@/components/checkout/LocationSelect.vue";

export default {
  name: "CheckoutForm",
  components: {
    "persoana-fizica": PersFizica,
    "persoana-juridica": PersJuridica,
    "payment-methods": PaymentMethods,
    "form-buttons": formButtons,
    "another-address": AnotherAddress,
    Terms,
    Form,
    // 'location-select': LocationSelect,
  },
  data() {
    return {
      activeTab: 1,
      createOrder: false,
    };
  },
  computed: {
    cartData() {
      return this.$store.getters["shop/cartData"];
    },
  },
  methods: {
    sendOrder(values) {
      const payload = {};
      payload.order = values.order;
      payload.user = values.user;
      payload.order.payment_method_id = +values.payment_method_id;
      payload.order.shipping_type_id = this.cartData.shipping_type_id;
      this.createOrder = true;
      this.$store.dispatch("shop/createOrder", payload).then((response) => {
        if(response) {
          this.createOrder = false;
        }
      });
    },
  },
  mounted() {
    // var cartData = this.$store.getters["shop/cartData"];
    // var items = [];
    // this.cartData.positions.forEach((value) => {
    //   items.push({
    //     id: value.product.id,
    //     name: value.product.name,
    //     quantity: value.quantity,
    //     price: value.price_per_unit,
    //   });
    // });
    // this.$gtag.event("begin_checkout", {
    //   items: items,
    //   coupon: "",
    // });
  },
};
</script>

<style>
._ajax_create_order .error {
  background-image: url("~@/assets/images/x-error.png") !important;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.alert_wrap .alert_box svg {
  color: #e74c3c;
  font-size: 36px;
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: inherit;
}

.checkout-area .alert_box svg {
  font-size: 30px;
}
</style>
