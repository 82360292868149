<template>
  <div class="detalii-livrare" id="detalii-livrare-persoana">
    <h3>Detalii de facturare persoana fizica</h3>
    <Field
      type="hidden"
      name="order.property.client_type"
      id="persoana_id_client"
      value="fizica"
    />
    <div class="fizica row">
      <div class="col-lg-6 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_name">Nume:</label><span class="steluta"> *</span>
          <Field
            type="text"
            name="user.last_name"
            id="checkout_billing_name"
            value=""
            placeholder="Nume"
            class="form-control"
            :class="{ error: errors['user.last_name'] }"
            :rules="personBillingLastnameRules"
          />
          <ErrorMessage class="form-error" name="user.last_name" />
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_last_name">Prenume:</label
          ><span class="steluta"> *</span>
          <Field
            type="text"
            name="user.name"
            id="checkout_billing_last_name"
            value=""
            placeholder="Prenume"
            class="form-control"
            :class="{ error: errors['user.name'] }"
            :rules="personBillingLastnameRules"
            required
          />
          <ErrorMessage class="form-error" name="user.name" />
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div v-if="judete" class="info_req mb-3">
          <label for="checkout_billing_state">Judet:</label
          ><span class="steluta"> *</span>
          <vue-select
            id="checkout_billing_state"
            name="order.property.billing_state"
            v-model="menuSelected"
            :options="judete"
            :value-by="'nume'"
            :label-by="'nume'"
            :close-on-select="true"
            :clear-on-select="true"
            :searchable="true"
            :placeholder="'Selecteaza judet'" 
            :search-placeholder="'Cauta judet'"> 
          </vue-select>
          <ErrorMessage class="form-error" name="order.property.billing_state" />
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div v-if="judete" class="info_req mb-3">
          <label for="checkout_billing_city">Localitatea:</label
          ><span class="steluta"> *</span>
          <vue-select 
            :key="renderKey"
            id="checkout_billing_city"
            name="order.property.billing_city"
            v-model="selecteduserCity"
            :disabled="userCities.length == 0"
            :options="userCities" 
            :value-by="'nume'" 
            :label-by="'nume'" 
            :close-on-select="true" 
            :clear-on-select="true"
            :searchable="true" 
            :placeholder="'Selecteaza localitate'" 
            :search-placeholder="'Cauta localitate'"> 
          </vue-select>
          <ErrorMessage class="form-error" name="order.property.billing_city" />
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_street">Strada:</label
          ><span class="steluta"> *</span>
          <Field
            type="text"
            name="order.property.billing_street"
            id="checkout_billing_street"
            value=""
            placeholder="Strada"
            class="form-control"
            :class="{ error: errors['order.property.billing_street'] }"
            :rules="personBillingStreetRules"
          />
          <ErrorMessage class="form-error" name="order.property.billing_street" />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_number">Nr. Strada:</label
          ><span class="steluta"> *</span>
          <Field
            type="text"
            name="order.property.billing_house"
            id="checkout_billing_number"
            value=""
            placeholder="Numar"
            class="form-control"
            :class="{ error: errors['order.property.billing_house'] }"
            :rules="personBillingNumberRules"
            @keyup="findPostalCode"
          />
          <ErrorMessage class="form-error" name="order.property.billing_house" />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_zipcode">Cod Postal:</label
          ><span class="steluta"> *</span>
          <Field
            type="text"
            name="order.property.billing_postcode"
            id="checkout_billing_postal_zipcode"
            v-model="userZipcode"
            placeholder="Cod postal"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_addr_block">Bloc:</label>
          <Field
            type="text"
            name="order.property.billing_block"
            id="checkout_billing_addr_block"
            value=""
            placeholder="Numar bloc"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_addr_apartment">Apartament:</label>
          <Field
            type="text"
            name="order.property.billing_flat"
            id="checkout_billing_addr_apartment"
            value=""
            placeholder="Apartament"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_addr_entrance">Intrare:</label>
          <Field
            type="text"
            name="order.property.billing_entrance"
            id="checkout_billing_addr_entrance"
            value=""
            placeholder="Intrare"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="info_req form-group">
          <label for="checkout_billing_phone">Telefon:</label
          ><span class="steluta"> *</span>
          <Field
            type="text"
            name="order.property.phone"
            id="checkout_billing_phone"
            value=""
            placeholder="Numar telefon"
            class="form-control"
            :class="{ error: errors['order.property.phone'] }"
            :rules="personBillingPhoneRules"
          />
          <ErrorMessage class="form-error" name="order.property.phone" />
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="info_req form-group">
          <label for="checkout_persoana_billing_email">Email:</label>
          <Field
            type="email"
            name="user.email"
            id="checkout_persoana_billing_email"
            value=""
            placeholder="Email"
            class="form-control"
            :rules="personBillingEmailRules"
             :class="{ error: errors['user.email'] }"
          />
           <ErrorMessage class="form-error" name="user.email" />
        </div>
      </div>
      <div class="col-12 col-lg-12">
        <div class="form-group">
          <label for="checkout_comments">Specificatii:</label>
          <Field
            as="textarea"
            name="order.property.comments"
            id="checkout_comments"
            placeholder="Specificatii"
            rows="4"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage, configure, useField } from "vee-validate";
import * as yup from "yup";
import autoComplete from "@tarekraafat/autocomplete.js";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

// Default values
configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// import Select2 from 'vue3-select2-component';


export default {
  name: "PersFizica",
  props: ["errors"],
  data() {
    return {
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
      phoneRegExp: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      personBillingLastnameRules: yup.string().required("Campul 'Nume' este obligatoriu"),
      personBillingFirstnameRules: yup
        .string()
        .required("Campul 'Prenume' este obligatoriu"),
      personBillingStateRules: useField('order.property.billing_state', yup.string().required("Campul 'Judet' este obligatoriu.")),
      personBillingCityRules: useField('order.property.billing_city', yup.string().required("Campul 'Localitate' este obligatoriu.")),
      personBillingStreetRules: yup.string().required("Campul 'Strada' este obligatoriu"),
      personBillingNumberRules: yup
        .string()
        .required("Campul 'Nr. Strada ' este obligatoriu"),
      personBillingPhoneRules: yup
        .string()
        .matches(phoneRegExp, "Numarul de telefon nu este valid")
        .required("Campul 'Telefon' este obligatoriu"),
       personBillingEmailRules: yup
        .string().email("Email-ul trebuie sa fie valid!"),
      menuSelected: "",
      judete: [],
      userCities: [],
      selecteduserCity: "",
      selecteduserCounty: "",
      userZipcode: "",
      autocompleteObj: {},
      disableStreetInput: true,
      renderKey: 0,
    };
  },

  watch: {
    menuSelected: function () {
      // Clear previously selected values
      // this.userCountries = [];
      this.userCities = [];
      // this.selecteduserCountry = "";
      this.selecteduserCity = "";
      this.disableStreetInput = true;
      this.userZipcode =  "";
      // Populate list of userCountries in the second dropdown
      if (this.menuSelected.length > 0) {
        const county = this.judete.find((element) => element.nume === this.menuSelected);
        this.selecteduserCounty = county.id;
        this.personBillingStateRules.value = county.nume;
        this.renderKey++;
        this.$store.dispatch("shop/getLocationData", county.id).then((response) => {
          this.userCities = response;
        });
      }
    },
    selecteduserCity: function() {
      let payload = [];
      payload.state = this.selecteduserCounty;
      payload.city_name = this.selecteduserCity;
      payload.street = "";
      payload.number = "";
      this.personBillingCityRules.value = payload.city_name;

      var address;
      this.$store.dispatch("shop/getPostalCode", payload).then((response) => {
        // console.log(response);
        this.disableStreetInput = false;
        if(response.length == 1) {
          if(typeof this.autocompleteObj.data !== "undefined") {
            this.autocompleteObj.data.src = response;
          }
          address = response[0];
          this.userZipcode = address.zip_code;
        } else {
          this.autocompleteObj = new autoComplete({
            selector: "#checkout_billing_street",
            threshold: 2,
            data: {
              src: response,
              keys: ["full_adress"]
            },
            events: {
              input: {
                  selection: (event) => {
                      const selection = event.detail.selection.value;
                      this.autocompleteObj.input.value = selection.value;
                      this.userZipcode = selection.zip_code;
                      // console.log(selection);
                  },
              }
            },
            resultsList: {
              maxResults: 50,
            }
          });
          
        }
      });
      
    }
  },

  created() {
    this.$store.dispatch("shop/getLocationData").then((response) => { this.judete = response; });
  },
  components: {
    Field,
    ErrorMessage,
    "vue-select": VueSelect,
  },

  methods: {
    findPostalCode() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let payload = [];
        payload.state = this.selecteduserCounty;
        payload.city_name = this.selecteduserCity;
        payload.street = document.getElementById("checkout_billing_street").value;
        payload.number = document.getElementById("checkout_billing_number").value;
        
        var address;
        if(payload.city_name.length > 0 && payload.street.length > 0 && payload.number.length > 0) {
          this.$store.dispatch("shop/getPostalCode", payload).then((response) => {
            // console.log(response);
            if(response.length == 1) {
              address = response[0];
              this.userZipcode = address.zip_code;
            }
          });
        }
      }, 700);
    }
  }
};
</script>

<style>
@import "~@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css";

#detalii-livrare-persoana label {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 15px;
}

#detalii-livrare-persoana .vue-select {
  width:100%;
  height: 40px;
}

#detalii-livrare-persoana .vue-input {
  line-height: 30px;
  padding-left: 0.75em;
}

#detalii-livrare-persoana .vue-input input {
  font-size: inherit;
}

#detalii-livrare-persoana .vue-select {
  background-color: #fff;
}
#detalii-livrare-persoana .vue-select[aria-disabled='true'] {
    background-color: rgba(239, 239, 239);
}

#detalii-livrare-persoana .vue-dropdown-item.highlighted {
  background-color: #f3f3f3;
}

#detalii-livrare-persoana .vue-dropdown-item.selected.highlighted {
  background-color: #f3f3f3;
  pointer-events: none;
}

#detalii-livrare-persoana .autoComplete_wrapper {
  display: block;
}

#detalii-livrare-persoana .autoComplete_wrapper > input {
  width: 100%;
}

#detalii-livrare-persoana .autoComplete_wrapper > ul {
  z-index: 1;
}
</style>
