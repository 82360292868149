<template>
  <div class="fieldset" id="detalii-comanda">
    <div class="" :class="{ error: errors['terms'] }">
      <Field
        type="checkbox"
        name="terms"
        value="regulament"
        id="checkout_agree"
        
        :rules="validateRules"
      />
      <label class="checkout_value inline" for="checkout_agree"
        >Sunt de acord cu
        <a href="/termeni-si-conditii.html" target="_blank"
          >regulamentul de achizitionare <span class="steluta"> *</span></a
        ></label
      >
      <br />
      <ErrorMessage class="form-error" name="terms" />
    </div>
    <div class="form-group"></div>
    <div class="" :class="{ error: errors['policy'] }">
      <Field
        type="checkbox"
        name="policy"
        value="policy"
        id="checkout_agree2"
        :rules="validatePolicy"
      />
      <label class="checkout_value inline" for="checkout_agree2"
        >Sunt de acord cu
        <a href="/politica-de-confidentialitate-a-datelor.html" target="_blank"
          >politica de confidentialitate a datelor
          <span class="steluta"> *</span></a
        ></label
      >
      <br />
      <ErrorMessage class="form-error" name="policy" />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "Terms",
  props: ['errors'],
  components: {
    Field,
    ErrorMessage,
  },
  methods: {
    validateRules(value) {
      if (!value) {
        return "Este necesar sa bifati casuta pentru a finaliza comanda.";
      }
      return true;
    },
    validatePolicy(value) {
      if (!value) {
        return "Este necesar sa bifati casuta pentru a finaliza comanda.";
      }
      return true;
    },
  },
};
</script>

<style scoped>


.error {
	background-image: url('~@/assets/images/x-error.png') !important;
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: calc(100% - 10px);
 }

 .error {
      border: 1px solid #a94442 !important;
}


</style>