<template>
  <fieldset class="detalii-facturare" id="detalii-facturare-firma">
    <h3>Detalii de facturare persoana juridica</h3>
    <Field
      type="hidden"
      name="order.property.client_type"
      id="company_id_client"
      value="juridica"
    />

    <div class="fizica">
      <div class="position_left row">
        <div class="col-md-6 col-xs-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_cui">Cod unic(CUI):</label
            ><span class="steluta"> *</span>
            <Field
              type="text"
              v-model="company.cui"
              name="order.property.billing_cui"
              id="checkout_company_billing_cui"
              value=""
              placeholder="Introduceti CUI-ul pentru a genera corect datele firmei"
              class="form-control"
              :class="{ error: errors['order.property.billing_cui'] }"
              :rules="companyBillingCuiRules"
              style="width: 88.7%; display: inline-block"
            />
            <button
              style="width: 10%; display: inline-block; height: 40px; vertical-align: top"
              type="button"
              id="btn_checkout_company_billing_cui"
              class="btn btn-success form-control"
              @click="getCompanyData"
            >
              <font-awesome-icon icon="search" />
            </button>
            <ErrorMessage class="form-error" name="order.property.billing_cui" />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <label for="checkout_company_billing_name">Nume companie:</label
            ><span class="steluta"> *</span>
            <Field
              type="text"
              name="user.name"
              id="checkout_company_billing_name"
              value=""
              v-model="company.name"
              placeholder="Firma"
              class="form-control"
              :class="{ error: errors['user.name'] }"
              :rules="companyBillingNameRules"
            />
            <ErrorMessage class="form-error" name="user.name" />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <label for="checkout_company_billing_j"
              >Cod inregistrare (Reg. Com. / J):</label
            >
            <Field
              type="text"
              name="order.property.billing_reg_j"
              id="checkout_company_billing_j"
              value=""
              v-model="company.regj"
              placeholder="Reg. Com. / J"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <label for="checkout_company_billing_phone">Telefon: </label
            ><span class="steluta"> *</span>
            <Field
              type="text"
              v-model="company.phone"
              name="order.property.phone"
              id="checkout_company_billing_phone"
              value=""
              placeholder="Numar telefon"
              class="form-control"
              :class="{ error: errors['order.property.phone'] }"
              :rules="companyBillingPhoneRules"
            />
            <ErrorMessage class="form-error" name="order.property.phone" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div v-if="judete" class="info_req mb-3">
            <label for="checkout_company_billing_state">Judet:</label
            ><span class="steluta"> *</span>
            <vue-select
              id="checkout_company_billing_state"
              name="order.property.billing_state"
              v-model="menuSelected"
              :options="judete"
              :value-by="'nume'"
              :label-by="'nume'"
              :close-on-select="true"
              :clear-on-select="true"
              :searchable="true"
              :placeholder="'Selecteaza judet'" 
              :search-placeholder="'Cauta judet'"> 
            </vue-select>
            <ErrorMessage class="form-error" name="order.property.billing_state" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div v-if="judete" class="info_req mb-3">
            <label for="checkout_company_billing_city">Localitatea:</label
            ><span class="steluta"> *</span>
            <vue-select 
              :key="renderKey"
              id="checkout_company_billing_city"
              name="order.property.billing_city"
              v-model="selecteduserCity"
              :disabled="userCities.length == 0"
              :options="userCities"
              :value-by="'nume'" 
              :label-by="'nume'" 
              :close-on-select="true" 
              :clear-on-select="true"
              :searchable="true" 
              :placeholder="'Selecteaza localitate'" 
              :search-placeholder="'Cauta localitate'"> 
            </vue-select>
            <ErrorMessage class="form-error" name="order.property.billing_city" />
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_address">Strada:</label
            ><span class="steluta"> *</span>
            <Field
              type="text"
              v-model="company.address"
              name="order.property.billing_street"
              id="checkout_company_billing_address"
              value=""
              placeholder="Adresa"
              class="form-control"
              :class="{ error: errors['order.property.billing_street'] }"
              :rules="companyBillingAddressRules"
            />
            <ErrorMessage class="form-error" name="order.property.billing_street" />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_number">Nr. Strada:</label
            ><span class="steluta"> *</span>
            <Field
              type="text"
              v-model="company.nr"
              name="order.property.billing_house"
              id="checkout_company_billing_number"
              value=""
              placeholder="Numar"
              class="form-control"
              :class="{ error: errors['order.property.billing_house'] }"
              :rules="companyBIllingNumberRules"
              @keyup="findPostalCode"
            />
            <ErrorMessage class="form-error" name="order.property.billing_house" />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_zipcode">Cod Postal:</label>
            <Field
              type="text"
              name="order.property.billing_postcode"
              id="checkout_company_billing_zipcode"
              value=""
              placeholder="Cod postal"
              class="form-control"
              v-model="userZipcode"
            />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_block">Bloc:</label>
            <Field
              type="text"
              v-model="company.block"
              name="order.property.billing_block"
              id="checkout_company_billing_block"
              value=""
              placeholder="Numar bloc"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_apartament">Apartament:</label>
            <Field
              type="text"
              v-model="company.flat"
              name="order.property.billing_flat"
              id="checkout_company_billing_apartament"
              value=""
              placeholder="Apartament"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="info_req form-group">
            <label for="checkout_company_billing_entrance">Intrare:</label>
            <Field
              type="text"
              name="order.property.billing_entrance"
              id="checkout_company_billing_entrance"
              value=""
              placeholder="Intrare"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <label for="checkout_company_billing_email">Email:</label>
            <Field
              type="email"
              v-model="company.email"
              name="user.email"
              id="checkout_company_billing_email"
              value=""
              placeholder="Email"
              class="form-control"
              :rules="companyBillingEmailRules"
              :class="{ error: errors['user.email'] }"
            />
             <ErrorMessage class="form-error" name="user.email" />
          </div>
        </div>
        <div class="col-md-12 col-xs-12">
          <div class="form-group">
            <label for="checkout_company_comments">Comentarii:</label>
            <Field
              as="textarea"
              name="order.property.comments"
              id="checkout_company_comments"
              placeholder="Comentarii"
              rows="6"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <InvalidCUIModal />
</template>

<script>
import { Field, ErrorMessage, useField } from "vee-validate";
import * as yup from "yup";
import InvalidCUIModal from "@/components/checkout/InvalidCUIModal.vue";
import autoComplete from "@tarekraafat/autocomplete.js";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default {
  name: "PersJuridica",
  props: ["errors"],
  components: {
    Field,
    ErrorMessage,
    InvalidCUIModal,
    "vue-select": VueSelect,
  },
  data() {
    return {
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
      companyBillingNameRules: yup
        .string()
        .required("Campul 'Nume companie' este obligatoriu"),
      companyBillingCuiRules: yup
        .string()
        .min(6, "Minim 6 caractere trebuie sa contina campul")
        .required("Campul 'Cod unic(CUI)' este obligatoriu"),
      companyBillingPhoneRules: yup
        .string()
        .matches(phoneRegExp, "Numarul de telefon nu este valid")
        .required("Campul 'Telefon' este obligatoriu"),
      companyBillingEmailRules: yup
        .string().email("Email-ul trebuie sa fie valid!"),
      companyBillingStateRules: useField('order.property.billing_state', yup.string().required("Campul 'Judet' este obligatoriu.")),
      companyBillingCityRules: useField('order.property.billing_city', yup.string().required("Campul 'Localitate' este obligatoriu.")),
      companyBillingAddressRules: yup
        .string()
        .required("Campul 'Strada' este obligatoriu"),
      companyBIllingNumberRules: yup
        .string()
        .required("Campul 'Nr. Strada ' este obligatoriu"),
      menuSelected: "",
      judete: [],
      userCities: [],
      selecteduserCity: "",
      company: {
        cui: "",
        name: "",
        regj: "",
        phone: "",
        address: "",
        nr: "",
        block: "",
        flat: "",
        email: "",
      },
      // manualSelect: false,
      selecteduserCounty: "",
      autocompleteObj: {},
      userZipcode: "",
      disableStreetInput: true,
      renderKey: 0,
    };
  },
  watch: {
    menuSelected: function () {
      // if (this.manualSelect) {
      //   this.manualSelect = false;
      //   return;
      // }
      // Clear previously selected values
      // this.userCountries = [];
      this.userCities = [];
      // this.selecteduserCountry = "";
      this.selecteduserCity = "";
      this.disableStreetInput = true;
      this.userZipcode =  "";
      // Populate list of userCountries in the second dropdown
      if (this.menuSelected.length > 0) {
        this.renderKey++;
        const county = this.judete.find(
          (element) =>
            element.nume.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() ===
            this.menuSelected.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        );
        this.selecteduserCounty = county.id;
        this.companyBillingStateRules.value = county.nume;
        this.$store.dispatch("shop/getLocationData", county.id).then((response) => {
          this.userCities = response;
        });
      }
    },
    selecteduserCity: function() {
      let payload = [];
      payload.state = this.selecteduserCounty;
      payload.city_name = this.selecteduserCity;
      payload.street = "";
      payload.number = "";
      this.companyBillingCityRules.value = payload.city_name;
  
      var address;
      this.$store.dispatch("shop/getPostalCode", payload).then((response) => {
        // console.log(response);
        this.disableStreetInput = false;
        if(response.length == 1) {
          if(typeof this.autocompleteObj.data !== "undefined") {
            this.autocompleteObj.data.src = response;
          }
          address = response[0];
          this.userZipcode = address.zip_code;
        } else {
          this.autocompleteObj = new autoComplete({
            selector: "#checkout_company_billing_address",
            threshold: 2,
            data: {
              src: response,
              keys: ["full_adress"]
            },
            events: {
              input: {
                  selection: (event) => {
                      const selection = event.detail.selection.value;
                      this.autocompleteObj.input.value = selection.value;
                      this.userZipcode = selection.zip_code;
                      // console.log(selection);
                  },
              }
            },
            resultsList: {
              maxResults: 50,
            }
          });
          
        }
      });
      
    },
  },
  computed: {
    companyData() {
      // console.log(this.$store.getters["shop/companyData"]);
      return this.$store.getters["shop/companyData"];
    },
  },
  created() {
    this.$store.dispatch("shop/getLocationData").then((response) => { this.judete = response; });
  },

  methods: {
    validateCui(value) {
      if (!value) {
        return "CUI-ul nu este corect.";
      }
      return true;
    },
    validatePhone(value) {
      if (!value) {
        return "Numarul de telefon trebuie sa contina cel putin 10 caractere.";
      }
      return true;
    },
    validateAddress(value) {
      if (!value) {
        return "Campul 'Strada' este obligatoriu.";
      }
      return true;
    },
    validateNumber(value) {
      if (!value) {
        return "Campul 'Nr. strada' este obligatoriu.";
      }
      return true;
    },
    validateCounty(value) {
      if (!value) {
        return "Campul 'Judet' este obligatoriu.";
      }
      return true;
    },
    validateCity(value) {
      if (!value) {
        return "Campul 'Localitate' este obligatoriu.";
      }
      return true;
    },
    getCities(callback) {
      setTimeout(() => {
        callback(this.userCities);
      }, 2000);
    },
    findCity(cityname) {
      this.getCities((cities) => {
        const city = cities.find((city) => city.nume === cityname);
        return city;
      });
    },
    clearCompanyData(obj) {
      for (var key in obj) {
        obj[key] = "";
      }
      this.menuSelected = "";
      this.selecteduserCity = "";
      this.userCities = [];
    },
    getCompanyData() {
      let cui = document.getElementById("checkout_company_billing_cui");
      var matches = cui.value.match(/(\d+)/);

      this.$store.dispatch("shop/getCompanyData", matches[0]).then((response) => {
        this.clearCompanyData(this.company);
        // this.manualSelect = true;
        if (!response.error) {
          this.company.name = response.name ? response.name : "";

          // cui
          let fiscalAttr =
            response.fiscalAttribute !== "" ? response.fiscalAttribute + "/" : "";
          this.company.cui = response.fiscalCode ? fiscalAttr + response.fiscalCode : "";
          this.company.regj = response.tradeRegisterNumber
            ? response.tradeRegisterNumber
            : "";
          this.company.phone = response.phone ? response.phone : "";
          this.company.address = response.address ? response.address : "";

          let address = response.address.split(",");

          address.forEach((item) => {
            if (item.includes("NR.")) {
              const addr_number = item.split("NR.")[1];
              this.company.nr = addr_number;
            }
            if (item.includes("AP.")) {
              const addr_ap = item.split("AP.")[1];
              this.company.flat = addr_ap;
            }
            if (item.includes("BL.")) {
              const addr_bl = item.split("BL.")[1];
              this.company.block = addr_bl;
            }
          });

          const county = this.judete.find((element) => {
            return (
              element.nume.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() ==
              response.county.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            );
          });
        
          if(county) {
            this.menuSelected = county.nume;
            this.renderKey++;
            if (response.city) {
              this.$store.dispatch("shop/getLocationData", county.id).then((cityResponse) => {
                this.userCities = cityResponse;
                const city = this.userCities.find(
                  (element) =>
                    element.nume.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() ===
                    response.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                );
                this.selecteduserCity = city.nume;
              });
            }
          } else {
            this.renderKey++;
            this.menuSelected = "Bucuresti";
            this.selecteduserCity = "";
          }

        } else {
          this.$store.dispatch("forms/changeInvalidCUI", true);
          this.$store.dispatch("forms/invalidCuiMessage", response.error);
          // this.manualSelect = true;
          this.clearCompanyData(this.company);

          this.menuSelected = "";
          this.selecteduserCity = "";
        }
      });
    },
    findPostalCode() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let payload = [];
        payload.state = this.selecteduserCounty;
        payload.city_name = this.selecteduserCity;
        payload.street = document.getElementById("checkout_company_billing_address").value;
        payload.number = document.getElementById("checkout_company_billing_number").value;
        
        var address;
        if(payload.city_name.length > 0 && payload.street.length > 0 && payload.number.length > 0) {
          this.$store.dispatch("shop/getPostalCode", payload).then((response) => {
            if(response.length == 1) {
              address = response[0];
              this.userZipcode = address.zip_code;
            }
          });
        }
      }, 700);
    }
  },
};
</script>
<style>
  @import "~@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css";

  #detalii-facturare-firma label {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 15px;
}

#detalii-facturare-firma .vue-select {
  width:100%;
  height: 40px;
}

#detalii-facturare-firma .vue-input {
  line-height: 30px;
  padding-left: 0.75em;
}

#detalii-facturare-firma .vue-input input {
  font-size: inherit;
}

#detalii-facturare-firma .vue-select {
  background-color: #fff;
}
#detalii-facturare-firma .vue-select[aria-disabled='true'] {
    background-color: rgba(239, 239, 239);
}

#detalii-facturare-firma .vue-dropdown-item.highlighted {
  background-color: #f3f3f3;
}

#detalii-facturare-firma .vue-dropdown-item.selected.highlighted {
  background-color: #f3f3f3;
  pointer-events: none;
}

#detalii-facturare-firma .autoComplete_wrapper {
  display: block;
}

#detalii-facturare-firma .autoComplete_wrapper > input {
  width: 100%;
}

#detalii-facturare-firma .autoComplete_wrapper > ul {
  z-index: 1;
}
</style>
