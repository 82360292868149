<template>
  <div class="fieldset" id="details-payment" :class="{ error: errors['payment_method_id'] }">
    <div id="title_wrapper">
      <span class="fieldset_title">Metoda de plata</span>
      <span class="steluta"> *</span><br />
    </div>
    <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" class="form-group">
      <Field
        type="radio"
        :name="paymentMethod.code != 'rate-5' ? 'payment_method_id' : 'payment_method_options'"
        :id="'pay-' + paymentMethod.code"
        :value="paymentMethod.id"
        :rules="paymentMethod.code != 'rate-5' ? validatePayment : ''"
        v-model="selectedPayment"
        @change="setPaymentMethod(paymentMethod.id)"
      />
      <label class="checkout_value inline" :for="'pay-' + paymentMethod.code"
        >{{ paymentMethod.name }}
      </label> 
      <div v-if="paymentMethod.preview_text && selectedPayment == paymentMethod.id"> {{ paymentMethod.preview_text }} </div>
      <div v-if="paymentMethod.code == 'rate-5' && selectedPayment == paymentMethod.id" class="rate-options">
        <div :class="errors['payment_method_id'] ? 'rate-options-error' : ''"><img class="warning-display" width="14" height="14" src="@/assets/images/red-warning.png" /> Te rugăm să selectezi una dintre metodele de mai jos:</div>
        <div>
            <Field
              type="radio"
              name="payment_method_id"
              id="pay-rate-bt"
              :value="getPaymentId('rate-bt')"
              :rules="validatePayment"
              @change="setPaymentMethod(getPaymentId('rate-bt'))"
            />
            <label for="pay-rate-bt"><img src="@/assets/images/starbtcard.png" /></label>
        </div>
        <div>
            <Field
              type="radio"
              name="payment_method_id"
              id="pay-rate-avantaj"
              :value="getPaymentId('rate-avantaj')"
              :rules="validatePayment"
              @change="setPaymentMethod(getPaymentId('rate-avantaj'))"
            />
            <label for="pay-rate-avantaj"><img src="@/assets/images/cardavantaj.png" /></label>
        </div>
        <div>
            <Field
              type="radio"
              name="payment_method_id"
              id="pay-rate-garanti"
              :value="getPaymentId('rate-garanti')"
              :rules="validatePayment"
              @change="setPaymentMethod(getPaymentId('rate-garanti'))"
            />
            <label for="pay-rate-garanti"><img src="@/assets/images/garanti.png" /></label>
        </div>
        <div>
            <Field
              type="radio"
              name="payment_method_id"
              id="pay-rate-alpha"
              :value="getPaymentId('rate-alpha')"
              :rules="validatePayment"
              @change="setPaymentMethod(getPaymentId('rate-alpha'))"
            />
            <label for="pay-rate-alpha"><img src="@/assets/images/alphabank.png" /></label>
        </div>
      </div>
    </div>
    <ErrorMessage class="form-error" name="payment_method_id" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "PaymentMethods",
  props: ['errors', 'personType'],
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      selectedPayment: null,
    };
  },
  computed: {
    paymentMethods() {
      var payments = [];
      var methods = this.$store.getters["shop/paymentMethods"];
      var cartData = this.$store.getters["shop/cartData"];

      methods.filter((item) => {
        var exclude = false;
        if(item.code == 'ramburs' && this.personType == "fizica" && cartData?.total_price?.price_value > 10000) {
          exclude = true;
        }
        if(item.code == 'ramburs' && this.personType == "juridica" && cartData?.total_price?.price_value > 5000) {
          exclude = true;
        }

        if((item.code == 'rate-5' || !item.code.includes('rate-')) && !exclude) {
          payments.push(item);
        }
      })
      return payments;
    },
  },
  methods: {
    validatePayment(value) {
      if (!value || value == this.getPaymentId('rate-5')) {
        return "Este necesar sa alegeti o metoda de plata";
      }
      return true;
    },
    setPaymentMethod(payment_method_id) {
      return this.$store.dispatch("shop/getCart", payment_method_id);
    },
    getPaymentId(payment_method_code) {
      var payment_id = "";
      var methods = this.$store.getters["shop/paymentMethods"];
      methods.filter((item) => {
        if(item.code == payment_method_code) {
          payment_id = item.id;
        }
      })
      return payment_id;
    }
  },
  mounted() {
    this.$store.dispatch("shop/getPaymentMethods");
    this.$store.dispatch("shop/getCart");
  }
  
};
</script>

<style scoped>

.error {
  background-position: calc(100% - 10px) 5% !important;
  border: 1px solid #a94442 !important;
}

.rate-options div {
  margin-left: 0 !important;
  margin-bottom: 7px;
}

.rate-options label img{
  height: 32px;
}

.rate-options-error {
  color:#a94442 !important;
  font-family: Montserrat Bold;
}

.rate-options .warning-display {
  display: none;
}

.rate-options .rate-options-error .warning-display {
  display: inline-block;
}

</style>