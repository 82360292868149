<template>
  <div class="cart-actions">
    <router-link :to="{ name: 'Cart' }" class="btn">Editeaza Cos Cumparaturi</router-link>
    <div :class="{ blockBtn: created }" class="vld-parent">
      <input
        type="submit"
        name="save_order"
        value="Finalizeaza Comanda"
        class="btn btn-red final-order"
        @click="submitForm"
      />
    </div>
    <loading :active="isLoading">
      <spinner :spinner="isLoading"> </spinner>
    </loading>
  </div>
</template>

<script>
// Import component
import Spinner from "@/components/blocks/Spinner.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "formButton",
  props: {
    created: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loading,
    Spinner,
  },
  computed: {
    isLoading() {
      return this.created;
    }
  },
  methods: {
    submitForm() {
      this.$nextTick(() => {
        setTimeout(() => {
          //scroll to the first error
          let elementsInErrors = document.getElementsByClassName("error");
          if (elementsInErrors && elementsInErrors.length > 0) {
            elementsInErrors[0].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 100);
      });
    },
  },
};
</script>

<style scoped>
.blockBtn {
  pointer-events: none;
}
</style>
